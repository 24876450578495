// color
$primary-color: #009fff;
$primary-color-light: #d4f1f4; // baby blue
$primary-color-dark: #003454; // dark blue
// $secondary-color: #560a86; // 7122fa
$secondary-color: #7122fa; //
$secondary-color-1: #2f8473;
$accent-color-1: #b34f00;
$accent-color-2: #ff2903;
$accent-color-3: #04d9ff; //neon blue
$accent-color-grey: #3e4756;
$accent-color-magenta: #c81fff;
